/* eslint-disable no-useless-escape */
export default (t) => {
    return [
        {
            question: t('What payment methods do you accept?'),
            answer: (<> {t('After clicking the "Buy" button, you will be redirected to a payment form provided by our payment provider, Stripe, where you can purchase the license with any credit or debit card')} </>)
        },
        {
            question: t('Who can buy a license?'),
            answer: (<> {t('MetaDefender Cloud licenses are sold exclusively to businesses of any size that do not come into conflict with our anti-malware engine providers or directly with OPSWAT, either by providing similar services or directly competing using results provided by OPSWAT. Also, businesses residing in U.S. embargoed countries are prohibited from buying licenses')} </>)
        },
        {
            question: t('How many engines are part of commercial multiscanning?'),
            answer: (<p dangerouslySetInnerHTML={{ __html: t('Please see our <a href=\"/licensing\" rel=\"noopener noreferrer\" target=\"_blank\">licensing page</a>') }}/>)
        },
        {
            question: t('How do you handle privacy?'),
            answer: (<p dangerouslySetInnerHTML={{ __html: t('OPSWAT is GDPR compliant, and commercial accounts have private processing. Read more in our <a href=\"https://www.opswat.com/legal/privacy-policy\" rel=\"noopener noreferrer\" target=\"_blank\">privacy policy</a>') }}/>)
        },
        {
            question: t('How can I disable auto-renewal?'),
            answer: (<p dangerouslySetInnerHTML={{ __html: t('After a purchase has been made, head over to your <a href=\"/account\" rel=\"noopener noreferrer\" target=\"_blank\">account page</a>. From there, you have a \"Manage subscription\" button which will open the stripe portal and allow you to cancel the renewal of the current subscription') }}/>)
        },
        {
            question: t('How can I upgrade an existing subscription?'),
            answer: (<> {t('Easy. Get in contact with our sales team and they will assist you in choosing the right plan')} </>)
        },
        {
            question: t('Where can I get a copy of the receipt?'),
            answer: (<p dangerouslySetInnerHTML={{ __html: t('After a purchase has been made, head over to your <a href=\"/account\" rel=\"noopener noreferrer\" target=\"_blank\">account page</a>. From there, you have access to all your receipts and invoices for every purchase and renewal ever performed') }}/>)
        },
        {
            question: t('How can I get a demo account?'),
            answer: (<> {t('Anyone can register for a free account. Click the "Sign in" button from the header bar to go to our register page and signup with your email address to start using MetaDefender Cloud immediately. All new accounts also receive an apikey for API access')} </>)
        },
        {
            question: t('Am I charged extra if I exceed my daily limits?'),
            answer: (<> {t('For commercial users, when the daily limit is reached, the service will not accept any more requests, throwing an HTTP 429 error for limit exceeded')} <br /> {t('For enterprise customers, there is a 10% limit exceed permitted, and depending on the contract, additional requests can be allowed for a fixed price per request')} </>)
        },
        {
            question: t('Do you have an on-premise solution?'),
            answer: (<p dangerouslySetInnerHTML={{ __html: t('Yes, MetaDefender Core. Please head over to our dedicated <a href=\"https://www.opswat.com/products/metadefender/api\" rel=\"noopener noreferrer\" target=\"_blank\">webpage</a> to find out more') }}/>)
        }
    ];
};
